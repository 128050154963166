import { default as index7TcvhLVvFgMeta } from "/usr/src/nuxt-app/pages/collectPoint/index.vue?macro=true";
import { default as indexiElVe6VRvqMeta } from "/usr/src/nuxt-app/pages/contact/index.vue?macro=true";
import { default as indexksxF3o1CaDMeta } from "/usr/src/nuxt-app/pages/dashboard/index.vue?macro=true";
import { default as _91id_93aPw4VRzj9VMeta } from "/usr/src/nuxt-app/pages/forgot-password/[id].vue?macro=true";
import { default as indexU97qJh7JuiMeta } from "/usr/src/nuxt-app/pages/forgot-password/index.vue?macro=true";
import { default as indexAIEsNBhbiYMeta } from "/usr/src/nuxt-app/pages/gains/index.vue?macro=true";
import { default as indexuSsRSTZYjlMeta } from "/usr/src/nuxt-app/pages/login/index.vue?macro=true";
import { default as _91id_93OYbZTwlVB9Meta } from "/usr/src/nuxt-app/pages/recupBac/[id].vue?macro=true";
import { default as indexNYSnbtDIlDMeta } from "/usr/src/nuxt-app/pages/recupBac/index.vue?macro=true";
import { default as indexXWUBO5hB8yMeta } from "/usr/src/nuxt-app/pages/register/index.vue?macro=true";
import { default as indexmk1DraoT9iMeta } from "/usr/src/nuxt-app/pages/settings/account/index.vue?macro=true";
import { default as indexI1TTlwXbXFMeta } from "/usr/src/nuxt-app/pages/settings/notification/index.vue?macro=true";
export default [
  {
    name: index7TcvhLVvFgMeta?.name ?? "collectPoint",
    path: index7TcvhLVvFgMeta?.path ?? "/collectPoint",
    meta: index7TcvhLVvFgMeta || {},
    alias: index7TcvhLVvFgMeta?.alias || [],
    redirect: index7TcvhLVvFgMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/collectPoint/index.vue").then(m => m.default || m)
  },
  {
    name: indexiElVe6VRvqMeta?.name ?? "contact",
    path: indexiElVe6VRvqMeta?.path ?? "/contact",
    meta: indexiElVe6VRvqMeta || {},
    alias: indexiElVe6VRvqMeta?.alias || [],
    redirect: indexiElVe6VRvqMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexksxF3o1CaDMeta?.name ?? "dashboard",
    path: indexksxF3o1CaDMeta?.path ?? "/dashboard",
    meta: indexksxF3o1CaDMeta || {},
    alias: indexksxF3o1CaDMeta?.alias || [],
    redirect: indexksxF3o1CaDMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93aPw4VRzj9VMeta?.name ?? "forgot-password-id",
    path: _91id_93aPw4VRzj9VMeta?.path ?? "/forgot-password/:id()",
    meta: _91id_93aPw4VRzj9VMeta || {},
    alias: _91id_93aPw4VRzj9VMeta?.alias || [],
    redirect: _91id_93aPw4VRzj9VMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/forgot-password/[id].vue").then(m => m.default || m)
  },
  {
    name: indexU97qJh7JuiMeta?.name ?? "forgot-password",
    path: indexU97qJh7JuiMeta?.path ?? "/forgot-password",
    meta: indexU97qJh7JuiMeta || {},
    alias: indexU97qJh7JuiMeta?.alias || [],
    redirect: indexU97qJh7JuiMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIEsNBhbiYMeta?.name ?? "gains",
    path: indexAIEsNBhbiYMeta?.path ?? "/gains",
    meta: indexAIEsNBhbiYMeta || {},
    alias: indexAIEsNBhbiYMeta?.alias || [],
    redirect: indexAIEsNBhbiYMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/gains/index.vue").then(m => m.default || m)
  },
  {
    name: indexuSsRSTZYjlMeta?.name ?? "login",
    path: indexuSsRSTZYjlMeta?.path ?? "/login",
    meta: indexuSsRSTZYjlMeta || {},
    alias: indexuSsRSTZYjlMeta?.alias || [],
    redirect: indexuSsRSTZYjlMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OYbZTwlVB9Meta?.name ?? "recupBac-id",
    path: _91id_93OYbZTwlVB9Meta?.path ?? "/recupBac/:id()",
    meta: _91id_93OYbZTwlVB9Meta || {},
    alias: _91id_93OYbZTwlVB9Meta?.alias || [],
    redirect: _91id_93OYbZTwlVB9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/recupBac/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNYSnbtDIlDMeta?.name ?? "recupBac",
    path: indexNYSnbtDIlDMeta?.path ?? "/recupBac",
    meta: indexNYSnbtDIlDMeta || {},
    alias: indexNYSnbtDIlDMeta?.alias || [],
    redirect: indexNYSnbtDIlDMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/recupBac/index.vue").then(m => m.default || m)
  },
  {
    name: indexXWUBO5hB8yMeta?.name ?? "register",
    path: indexXWUBO5hB8yMeta?.path ?? "/register",
    meta: indexXWUBO5hB8yMeta || {},
    alias: indexXWUBO5hB8yMeta?.alias || [],
    redirect: indexXWUBO5hB8yMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexmk1DraoT9iMeta?.name ?? "settings-account",
    path: indexmk1DraoT9iMeta?.path ?? "/settings/account",
    meta: indexmk1DraoT9iMeta || {},
    alias: indexmk1DraoT9iMeta?.alias || [],
    redirect: indexmk1DraoT9iMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/settings/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexI1TTlwXbXFMeta?.name ?? "settings-notification",
    path: indexI1TTlwXbXFMeta?.path ?? "/settings/notification",
    meta: indexI1TTlwXbXFMeta || {},
    alias: indexI1TTlwXbXFMeta?.alias || [],
    redirect: indexI1TTlwXbXFMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/settings/notification/index.vue").then(m => m.default || m)
  }
]