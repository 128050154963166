export default defineNuxtRouteMiddleware((to ) => {

  const token = useCookie('token');

  const allowedRoutesWithoutToken = ['/login', '/register', '/forgot-password'];
  const isAllowedRouteWithoutToken = allowedRoutesWithoutToken.includes(to.path) || to.path.startsWith('/forgot-password/');

  if (!token.value) {
    if (!isAllowedRouteWithoutToken) {
      return navigateTo('/login');
    }
  }
  else {
    const router = useRouter();
    const routes = router.getRoutes();

    const currentPath = to.path;
    const isRecupBacRoute = currentPath.startsWith('/recupBac');
    if (isRecupBacRoute) {
      return
    }
    else {
      const isValidRoute = routes.some(route => route.path === currentPath);
      if (!isValidRoute) {
        return navigateTo('/dashboard');
      }
    }
  }

});
