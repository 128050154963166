export default defineAppConfig({
    ui: {
        primary: 'blue',
        gray: 'cool',
        notifications: {
            position: 'top-0 bottom-auto'
        },
        button: {
            default: {
                color: 'white',
                size: 'md',
            }
        }
    }
})
