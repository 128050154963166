import revive_payload_client_hn6Ds4yNYu from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.3_floating-vue@5.2.2_unocss@0.59.3_vite@5.2.9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jJWdVHuHHK from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.3_floating-vue@5.2.2_unocss@0.59.3_vite@5.2.9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xarXidEm9o from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.3_floating-vue@5.2.2_unocss@0.59.3_vite@5.2.9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_LRr7ZDEj71 from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.3_floating-vue@5.2.2_unocss@0.59.3_vite@5.2.9/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_Z8FmGiIBfE from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.3_floating-vue@5.2.2_unocss@0.59.3_vite@5.2.9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_35610Qn83q from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.3_floating-vue@5.2.2_unocss@0.59.3_vite@5.2.9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_YNEaMDqKCg from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.3_floating-vue@5.2.2_unocss@0.59.3_vite@5.2.9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_1ET3pLBTVK from "/usr/src/nuxt-app/node_modules/.pnpm/@nuxt+ui@2.15.2_nuxt@3.11.2_vite@5.2.9_vue@3.4.23/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bUZQcWX9Cy from "/usr/src/nuxt-app/node_modules/.pnpm/@nuxt+ui@2.15.2_nuxt@3.11.2_vite@5.2.9_vue@3.4.23/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_lxM1hZi77b from "/usr/src/nuxt-app/node_modules/.pnpm/@nuxt+ui@2.15.2_nuxt@3.11.2_vite@5.2.9_vue@3.4.23/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_r2zA6Jmq71 from "/usr/src/nuxt-app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_fUEvfpaGmZ from "/usr/src/nuxt-app/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import composition_M6W0fMAqDn from "/usr/src/nuxt-app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.4_vue@3.4.23/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_Mi6G8DJszu from "/usr/src/nuxt-app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.4_vue@3.4.23/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_gJNESBxAir from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.3_floating-vue@5.2.2_unocss@0.59.3_vite@5.2.9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_hn6Ds4yNYu,
  unhead_jJWdVHuHHK,
  router_xarXidEm9o,
  debug_LRr7ZDEj71,
  payload_client_Z8FmGiIBfE,
  check_outdated_build_client_35610Qn83q,
  components_plugin_KR1HBZs4kY,
  prefetch_client_YNEaMDqKCg,
  slideovers_1ET3pLBTVK,
  modals_bUZQcWX9Cy,
  colors_lxM1hZi77b,
  plugin_client_r2zA6Jmq71,
  plugin_fUEvfpaGmZ,
  composition_M6W0fMAqDn,
  i18n_Mi6G8DJszu,
  chunk_reload_client_gJNESBxAir
]