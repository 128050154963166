<template>
    <img class="absolute inset-0 object-cover object-center w-full h-full blur-sm" src="/assets/background.webp" alt="Background" />
    <div class="fixed inset-0 flex justify-center items-center">
        <div class="sm:mx-auto sm:w-2/3 md:w-1/2 lg:w-1/3 bg-white rounded-3xl shadow-xl relative z-10 h-40">
            <div class="flex flex-col items-center justify-center h-full">
                <h1 class="text-center font-semibold text-xl">{{ $t('bad-url') }}</h1>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
</script>