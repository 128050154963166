<template>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  <UNotifications />
</template>

<style>
  img, button, input:focus-visible {
    outline: none;
  }

  .scrollbar::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    .scrollbar::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
    .scrollbar::-webkit-scrollbar-thumb {
        background: #0a0f1c;

    }
    .scrollbar::-webkit-scrollbar-thumb:hover,
    .scrollbar::-webkit-scrollbar-thumb:active {
        background: #4c586a;
    }
    .scrollbar::-webkit-scrollbar-track,
    .scrollbar::-webkit-scrollbar-track:hover,
    .scrollbar::-webkit-scrollbar-track:active {
        background: #64748b;
    }
</style>